<template>
  <div class="container">
    <div class="row">
      <div class="subject col-12">クライアント新規作成</div>
    </div>
    <div class="row client_name">
      <div class="col-2">
        クライアント名
        <sup class="mark">※</sup>
      </div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="client-name"
          name="client_name"
          v-model="client_name"
        />
      </div>
    </div>
    <p id="client-name-alert">クライアント名を入力してください</p>
    <div class="row event_priod">
      <div class="col-2">
        契約期間
        <sup class="mark">※</sup>
      </div>
      <div class="col-1 contract_start_day">
        <input
          style="width: 170px"
          type="date"
          id="contract-start-day"
          name="client_name"
          v-model="contract_start_day"
        />
      </div>
      <div class="wavy_line col-1"><span>〜</span></div>
      <div class="col-1 contract_finished_day">
        <input
          style="width: 170px"
          type="date"
          id="contract-finished-day"
          name="client_name"
          v-model="contract_finished_day"
        />
      </div>
    </div>
    <p id="contract-period-alert">期間を選択してください</p>
    <div class="row event_priod">
      <div class="col-2">
        契約プラン
        <sup class="mark">※</sup>
      </div>
      <div class="col-4">
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit50DBプラン"
            style="transform: scale(2)"
          /><span>Limit50DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit100DBプラン"
            style="transform: scale(2); margin-top: 16px"
          /><span>Limit100DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit500DBプラン"
            style="transform: scale(2); margin-top: 16px"
          /><span>Limit500DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="manual"
            style="transform: scale(2); margin-top: 16px"
          /><span style="margin-right: 3%; margin-left: 7%">Limit</span
          ><input
            v-model="contract_plan"
            type="text"
            id="manualInput"
            name="manualInput"
            required
            minlength="4"
            maxlength="8"
            size="10"
          /><span style="margin-left: 3%">DBプラン</span>
        </label>
      </div>
      <p id="plan-alert">プランを選択してください</p>
    </div>
    <div class="row event_priod">
      <div class="col-2">
        TikQ連携
        <sup class="mark">※</sup>
      </div>
      <div class="tikq col-4">
        <div class="tikq-wrap">
          <label>
            <input
              type="radio"
              class="radio_button"
              name="tikq"
              value="あり"
              style="transform: scale(2)"
            /><span class="tikq-select">あり</span>
          </label>
        </div>
        <div class="tikq-wrap">
          <label>
            <input
              type="radio"
              class="radio_button"
              name="tikq"
              value="なし"
              style="transform: scale(2)"
            /><span class="tikq-select">なし</span>
          </label>
        </div>
      </div>
    </div>
    <p id="tikq-alert">選択してください</p>
    <div class="row client_name">
      <div class="col-2">
        メールアドレス
        <sup class="mark">※</sup>
      </div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="client-name"
          name="mail"
          v-model="mail"
        />
      </div>
    </div>
    <p id="mail-alert">メールアドレスを入力してください</p>
    <div class="row client_name">
      <div class="col-2">
        管理者名(マスター)
        <sup class="mark">※</sup>
      </div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="client-name"
          name="maneger"
          v-model="maneger"
        />
      </div>
    </div>
    <p id="maneger-alert">管理者名を入力してください</p>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-1">
        <button class="reserve-button" type="submit" v-on:click="send()">
          保存
        </button>
      </div>
      <div class="col-1">
        <button
          class="cancel-button"
          type="submit"
          v-on:click="changeClientListHome()"
        >
          キャンセル
        </button>
      </div>
    </div>
    <div id="easyModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="reserve-text">内容を変更します。</p>
            <p class="reserve-text">本当によろしいですか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="hide()"
                >
                  いいえ
                </button>
              </div>
              <div>
                <button
                  class="modal-reserve-button"
                  type="submit"
                  v-on:click="rewriteClientInfo()"
                >
                  はい
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue-js-modal@1.3.31/dist/index.min.js"></script> 
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-functions.js"></script>
<script>
export default {
  name: "ClientRegisterComponent",
  data: function () {
    return {
      client_name: "",
      contract_start_day: "",
      contract_finished_day: "",
      contract_plan: "",
      checkValue: "",
      client_id: "",
      mail: "",
      maneger: "",
      password: "",
      createUserUid: "",
      seacretId: "",
      tikqAlignment: "",
    };
  },
  mounted() {
    console.log("clientRegister画面がマウントされました");
    this.authStateCheck();
    document.body.className = "login";
    document.querySelector("body").style.background = "#F8F8F8";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
  methods: {
    authStateCheck() {
      var sessionLen = sessionStorage.length;
      var sessionKey;
      for (var i = 0; i < sessionLen; i++) {
        sessionKey = sessionStorage.key(i);
        console.log(sessionKey);
        console.log("特定文字列があるか");
        console.log(sessionKey.indexOf("firebase:authUser"));
        if (sessionKey.indexOf("firebase:authUser") != -1) {
          sessionKey = true;
          break;
        } else {
          sessionKey = false;
        }
      }
      if (sessionKey == false) {
        this.$store.commit("resetUser");
        this.$router.push({ path: "/" });
      }
    },
    send: function () {
      var instance = this;
      //firebase storageにフォームの値を送信する
      let contaractPalnElements = document.getElementsByName("plan");
      let len = contaractPalnElements.length;
      for (let i = 0; i < len; i++) {
        if (contaractPalnElements.item(i).checked) {
          this.checkValue = contaractPalnElements
            .item(i)
            .value.replace(/Limit|DBプラン/g, "");
          if (this.checkValue == "manual") {
            this.checkValue = this.contract_plan;
            break;
          }
          break;
        }
      }

      //tikQ連携のラジオボタンの選択値を取得
      this.getTikqRadioButton();
      if (
        ////////////////////////////////////////////フォームの項目を全て記入したら登録開始
        this.client_name != "" &&
        this.contract_start_day != "" &&
        this.contract_finished_day != "" &&
        this.checkValue != "" &&
        this.mail != "" &&
        this.maneger != "" &&
        this.tikqAlignment != ""
      ) {
        //表示しているアラートを全て非表示にする
        document.getElementById("client-name-alert").style.display = "none";
        document.getElementById("contract-period-alert").style.display = "none";
        document.getElementById("plan-alert").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("maneger-alert").style.display = "none";
        document.getElementById("tikq-alert").style.display = "none";

        //ローディング表示
        this.waitLoading();

        //クライアントIDの生成
        this.getUniqueStr();

        //日付のフォーマットを変更する
        var changeFormatContractStartDay = this.contract_start_day.replace(
          /\-/g,
          "/"
        );
        var changeFormatContractFinishedDay =
          this.contract_finished_day.replace(/\-/g, "/");

        //マスターアカウントのパスワードを自動生成する
        this.createPassword();

        //シークレットIDを生成する
        this.setSeacretId();

        //クライアントとユーザーをDBに登録する
        this.setClientUser(
          instance,
          changeFormatContractStartDay,
          changeFormatContractFinishedDay
        );
      } else {
        ////////////////////////////////////もし新規作成に不備があるならアラートを表示させる
        if (this.client_name == "") {
          document.getElementById("client-name-alert").style.display = "inline";
        } else {
          document.getElementById("client-name-alert").style.display = "none";
        }
        if (this.contract_start_day == "" || this.contract_finished_day == "") {
          document.getElementById("contract-period-alert").style.display =
            "inline";
        } else {
          document.getElementById("contract-period-alert").style.display =
            "none";
        }
        if (this.checkValue == "") {
          document.getElementById("plan-alert").style.display = "inline";
        } else {
          document.getElementById("plan-alert").style.display = "none";
        }
        if (this.mail == "") {
          //メールアドレスのチェック
          document.getElementById("mail-alert").style.display = "inline";
        } else {
          document.getElementById("mail-alert").style.display = "none";
        }
        if (this.maneger == "") {
          //管理者名のチェック
          document.getElementById("maneger-alert").style.display = "inline";
        } else {
          document.getElementById("maneger-alert").style.display = "none";
        }
        if (this.tikqAlignment == "") {
          //TikQ連携のチェック
          document.getElementById("tikq-alert").style.display = "inline";
        } else {
          document.getElementById("tikq-alert").style.display = "none";
        }
      }
    },
    ////////////////////////////////////////////////
    /////
    /////説明
    /////TikQラジオボタンの選択値を取得しデータに保存する
    /////
    /////
    /////
    /////
    ////////////////
    /////////////////////
    ////////////////////////////
    ////////////////////////////////////////////////
    getTikqRadioButton: function () {
      //名前に「tikq」と付いた要素を取得
      let tikQRadioButtonElements = document.getElementsByName("tikq");

      //取得した要素の数
      let len = tikQRadioButtonElements.length;
      for (let i = 0; i < len; i++) {
        if (tikQRadioButtonElements.item(i).checked) {
          this.tikqAlignment = tikQRadioButtonElements.item(i).value;
          console.log(this.tikqAlignment);
          break;
        }
      }
    },
    createClient: async function (
      instance,
      changeFormatContractStartDay,
      changeFormatContractFinishedDay,
      existAccount
    ) {
      //保存先パス
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/client/" +
            this.client_id +
            "/" +
            this.seacretId +
            "/" +
            this.client_id +
            ".json"
        );
      const jsonString = JSON.stringify({
        client_id: this.client_id,
        seacretId: this.seacretId,
        client_name: this.client_name,
        contract_start_day: changeFormatContractStartDay,
        contract_finished_day: changeFormatContractFinishedDay,
        contract_plan: this.checkValue,
        mail: this.mail,
        password: this.password,
        maneger: this.maneger,
        used_db: 0,
        use_number: 0,
        update_date: this.getCreateTime(),
        tikQ_alignment: this.tikqAlignment,
      });
      var metadata = {
        customMetadata: {
          clientId: this.client_id,
        },
      };
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob, metadata).then(function (result) {
        instance.createDBfolder(instance, existAccount);
        //instance.setAccountFirebaseStorage(instance, existAccount);
      });
    },
    judge: function () {},
    //////////////////////////////////////////////
    ////////説明：
    /////authenticationにメールアドレスとパスワードを設定する
    /////
    /////
    /////
    /////////////////////////////////////////////////////////
    setClientUser: async function (
      instance,
      changeFormatContractStartDay,
      changeFormatContractFinishedDay
    ) {
      console.log("setClientUserが呼び出されました");
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.mail, this.password)
        .then((user) => {
          console.log("新しいユーザーが作られました");
          //のちにjsonファイル名として利用する
          this.createUserUid = user.user.uid;

          //サインアウトしないとfirebase storageのセキュリティルールにひっかかる
          firebase.auth().signOut(); //セッション関連でコメントアウト
          console.log("サインアウトしました");

          firebase
            .auth()
            .signInWithEmailAndPassword(
              this.$store.state.loginEmail,
              this.$store.state.loginPassword
            )
            .then((user) => {
              instance.createClient(
                instance,
                changeFormatContractStartDay,
                changeFormatContractFinishedDay,
                true
              );
            });
        })
        .catch((error) => {
          var instance = this;
          firebase
            .storage()
            .ref()
            .child("/userBelongClient/" + instance.mail + ".json")
            .getDownloadURL()
            .then((url) => {
              var xhr = new XMLHttpRequest();
              xhr.open("GET", url);
              xhr.onload = function (event) {
                var blob = xhr.response;
                var belongClientIdList = JSON.parse(blob);
                instance.createUserUid = belongClientIdList["uid"].uid;
                instance.password = belongClientIdList["password"].password;
                instance.createClient(
                  instance,
                  changeFormatContractStartDay,
                  changeFormatContractFinishedDay,
                  false
                );
              };
              xhr.send();
            });
        });
    },
    //////////////////////////////////////////////
    ////////説明：
    /////firebase storageにauthenticationに登録したユーザーを保存する
    /////
    /////
    /////
    /////////////////////////////////////////////////////////
    setAccountFirebaseStorage: async function (instance, existAccount) {
      const refAdmin = firebase
        .storage()
        .ref()
        .child("/account/" + this.client_id + "/managerList.json");

      var accountList = [];
      const jsonStringAdmin = JSON.stringify(accountList);
      var metadata = {
        customMetadata: {
          clientId: this.client_id,
        },
      };
      const blobAdmin = new Blob([jsonStringAdmin], {
        type: "application/json",
      });
      refAdmin.put(blobAdmin, metadata).then(function (result) {
        instance.setCreateClientId(existAccount);
      });
    },
    //////////////////////////////////////////////
    ////////説明：
    /////作成するクライアントのクライアントIDを追加して、storageに保存する
    /////
    /////
    /////
    /////////////////////////////////////////////////////////
    setCreateClientId: async function (existAccount) {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child("/adminClientList/clientIdList.json");
      var instance = this;

      //この配列に新しく作成したクライアントIDを格納する
      var clientIdList = this.$store.state.clientIdList;

      //新しく作成したクライアントID
      var clientId = {
        id: this.client_id,
        seacret: this.seacretId,
      };
      clientIdList.push(clientId);

      //jsonに変換
      const jsonString = JSON.stringify(clientIdList);
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        //プロジェクトフォルダを作成する
        instance.createProjectFolder(existAccount);
      });
    },
    //////////////////////////////////////////////
    ////////説明：クライアントIDを自動生成する
    /////
    /////
    /////
    /////引数:myStrong:実際は何も入らない
    /////////////////////////////////////////////////////////
    getUniqueStr: function (myStrong) {
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      this.client_id =
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16);
    },
    //////////////////////////////////////////////
    ////////説明：パスワードを自動生成する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    createPassword: function () {
      //英数字を用意する
      var letters = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";
      var string = letters + letters.toUpperCase() + numbers;
      //toUpperCase()  小文字を大文字に変換

      var len = 8; //8文字
      this.password = ""; //文字列が空っぽという定義をする
      for (var i = 0; i < len; i++) {
        this.password += string.charAt(
          Math.floor(Math.random() * string.length)
        );
        // charAt メソッドを用いて文字列から指定した文字を返す。
      }
    },
    //////////////////////////////////////////////
    ////////説明：クライアントを生成した日付を返す。この返された値をもとに
    /////        クライアントを表示させる順番を変更する。
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    getCreateTime: function () {
      //現在の日付取得
      var createDate = new Date();

      return createDate;
    },
    //////////////////////////////////////////////
    ////////説明：キャンセルボタンが押された時
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    changeClientListHome: function () {
      this.$router.push("/clientManager");
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを保存している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを保存した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    //////////////////////////////////////////////
    ////////説明：ユーザーのカスタムクレームにクライアントIDを登録する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    registerCustomClaim: function (clientIdList) {
      var instance = this;
      var router = this.$router;
      var addMessage = firebase.functions().httpsCallable("helloWorld");
      addMessage(clientIdList).then((result) => {
        //ローディング終了
        instance.downloaded();

        //クライアント一覧画面に戻る
        router.push("/clientManager");
      });
    },
    //////////////////////////////////////////////
    ////////説明：ユーザーが属するクライアントIDをDBから取得する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    getUserBelongClient: function (mail) {
      var instance = this;
      firebase
        .storage()
        .ref()
        .child("/userBelongClient/" + mail + ".json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var belongClientIdList = JSON.parse(blob);
            //jsonに新しくクライアントIDを追加する
            instance.addBelongClientId(belongClientIdList, instance.client_id);
          };
          xhr.send();
        });
    },
    //////////////////////////////////////////////
    ////////説明：ユーザーが属するクライアントのIDを追加する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    addBelongClientId: function (belongclientIdList, clientId) {
      belongclientIdList.groups[clientId] = true;

      //firebase storageに保存する
      this.setBelongClient(belongclientIdList);
    },
    //////////////////////////////////////////////
    ////////説明：firebase storageにユーザーが属するクライアントIDを保存する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    setBelongClient: function (belongClientIdList) {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child("/userBelongClient/" + this.mail + ".json");
      var instance = this;
      //jsonに変換
      const jsonString = JSON.stringify(belongClientIdList);
      const blob = new Blob([jsonString], { type: "application/json" });

      ref.put(blob).then(function (result) {
        //カスタムクレームを登録する
        instance.registerCustomClaim(belongClientIdList);
      });
    },
    //////////////////////////////////////////////
    ////////説明：firebasestorageにプロジェクトフォルダを作成する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    createProjectFolder: function (existAccount) {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/project/" + this.client_id + "/" + this.seacretId + "/project.json"
        );

      var router = this.$router;
      var instance = this;

      //この配列に空のプロジェクトを作成する
      var projectList = [];

      //jsonに変換
      const jsonString = JSON.stringify(projectList);
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        if (existAccount == true) {
          //ここにクライアントIDを格納する
          var groups = {};

          //ここにuidが格納される
          var uid = {};

          var password = {};

          //クライアントIDを登録
          groups[instance.client_id] = true;

          //uidを登録
          uid["uid"] = instance.createUserUid;

          password["password"] = instance.password;

          //functionに送るデータ
          var data = { groups, uid, password };

          //登録する
          instance.setBelongClient(data);
        } else {
          instance.getUserBelongClient(instance.mail);
        }
      });
    },
    //////////////////////////////////////////////
    ////////説明：クライアントID以外にログイン時に利用するクライアントを識別するIDを生成する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    setSeacretId: function (myStrong) {
      //英数字を用意する
      var letters = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";

      var string = letters + letters.toUpperCase() + numbers;
      //toUpperCase()  小文字を大文字に変換

      var len = 30; //8文字
      var password = ""; //文字列が空っぽという定義をする

      for (var i = 0; i < len; i++) {
        password += string.charAt(Math.floor(Math.random() * string.length));
        // charAt メソッドを用いて文字列から指定した文字を返す。
      }
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      var id =
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16);
      this.seacretId = password + id;
    },
    //////////////////////////////////////////////
    ////////説明：インフルエンサー用投稿フォーム時に閲覧するフォルダを作成
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    createDBfolder(instance, existAccount) {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child("/db/" + this.client_id + ".json");
      var instance = this;

      //このjsonはインフルエンサー投稿フォームで読み取られる
      var clientId = {
        clientId: this.client_id,
        contract_plan: this.checkValue,
        used_db: 0,
        used_number: 0,
      };

      //jsonに変換
      const jsonString = JSON.stringify(clientId);
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        instance.setAccountFirebaseStorage(instance, existAccount);
      });
    },
  },
};

//モーダルコンテンツ以外がクリックされた時
addEventListener("click", outsideClose);
function outsideClose(e) {
  if (e.target == document.getElementById("easyModal")) {
    document.getElementById("easyModal").style.display = "none";
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");
.client_rewrite_body {
  background-color: #f5f5f5;
}

.container {
  margin-top: 5%;
}

.subject {
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
  margin-left: 0%;
  color: #4a4a4a;
  opacity: 1;
}

.client_id {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.client_name {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.event_priod {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.tikq {
  display: flex;
}

.tikq-select {
  display: inline-block;
  margin-left: 16px;
}

.tikq-wrap {
  margin-right: 64px;
}

label {
  display: block;
}

span {
  margin-left: 7%;
}

.reserve-button {
  margin-top: 50%;
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #00979c 0%, #068587 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

@media (max-width: 1199px) {
  .cancel-button {
    margin-top: 50%;
    margin-left: 60%;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 1203px) {
  .cancel-button {
    margin-top: 50%;
    margin-left: 20%;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}

@media (max-width: 991px) {
  .cancel-button {
    margin-top: 50%;
    margin-left: 150%;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .cancel-button {
    margin-top: 150%;
    margin-left: 500%;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .cancel-button {
    margin-top: 50%;
    margin-left: 350%;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}

@media (min-width: 1200px) {
  .contract_start_day {
    margin-right: 7%;
  }
  .contract_finished_day {
    margin-left: 0.5%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (min-width: 1400px) {
  .contract_start_day {
    margin-right: 4.5%;
  }
  .contract_finished_day {
    margin-left: 0.5%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (max-width: 1199px) {
  .contract_start_day {
    margin-right: 10%;
  }
  .contract_finished_day {
    margin-left: 1.1%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (max-width: 991px) {
  .contract_start_day {
    margin-right: 16%;
  }
  .contract_finished_day {
    margin-left: 2%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 2%;
    width: 1%;
  }
}

@media (max-width: 767px) {
  .contract_start_day {
    margin-right: 25.5%;
  }
  .contract_finished_day {
    margin-left: 2.3%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 2%;
    width: 1%;
  }
}
#client-name-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#mail-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#maneger-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#contract-period-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#tikq-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.945);
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 30%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reserve-text {
  text-align: center;
  font-size: 18px;
}

.modalClose:hover {
  cursor: pointer;
}

.modal-body {
  padding: 10px 20px;
  color: black;
}

.text-content {
  margin-top: 10%;
}

.modal-reserve-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 80px;
  height: 30px;
  border: 0;
  color: #ffffff;
}

.modal-cancel-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
  width: 80px;
  height: 30px;
  border: 0;
  color: #ffffff;
}

.btn-modal {
  text-align: center;
  margin-bottom: 10%;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}

.mark {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  display: inline;
  color: red;
}

#plan-alert {
  margin-top: 0%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}
</style>
